import React, { useState } from 'react';
import { PageHeader } from '@ant-design/pro-components';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  Button,
  Form,
  Input,
  Modal,
  Space,
  Table,
  Typography,
  message,
} from 'antd';
import { useMutation, useQuery } from 'react-query';
import moment from 'moment';
import Request from '../../libs/request';
import { useForm } from 'antd/es/form/Form';

const DonorsList = () => {
  const [editRow, setEditRow] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();

  const [form] = useForm();
  const [editForm] = useForm();

  // get all donors
  const fetchDonors = useQuery('create-donor', () => {
    return Request.get('/donors');
  });

  // create a donor
  const createDonor = useMutation((payload) => {
    return Request.post(`/super-admin/donors`, payload);
  });

  // edit a donor
  const editDonor = useMutation((payload) => {
    return Request.post(`/super-admin/donors/${editRow?.donorId}`, payload);
  });

  // delete a donor
  const deleteDonor = useMutation(
    async (payload) => {
      return Request.post(`/super-admin/donors/${payload?.donorId}`);
    },
    {
      onError: (_, payload) =>
        message.error(`Couldn't delete ${payload.name}. Please try again.`),
    },
    {
      onSuccess: (_, payload) =>
        message.success(`Successfully disabled ${payload.name}!`),
    }
  );

  const columns = [
    {
      title: 'Created At',
      render: (row) => moment(row.addTs).format('YYYY-MM-DD HH:mm:ss'),
    },
    {
      title: 'Name',
      dataIndex: 'name',
    },
    {
      title: 'Actions',
      render: (row) => (
        <Space>
          <Button onClick={() => setEditRow(row)}>Edit</Button>
          <Button
            danger
            onClick={() => {
              Modal.confirm({
                centered: true,
                okText: 'Confirm Delete',
                okType: 'danger',
                title: 'Are you sure you want to delete this donor?',
                onOk: () => deleteDonor.mutate(row),
              });
            }}
          >
            Delete
          </Button>
        </Space>
      ),
    },
  ];

  const onModalCancel = () => navigate({ hash: '' });

  return (
    <>
      <PageHeader
        title='Donors List'
        onBack={() => navigate(-1)}
        extra={[
          <Button
            type='primary'
            onClick={() => navigate({ hash: 'add-new-donor' })}
          >
            Add Donor
          </Button>,
        ]}
      />
      <Table columns={columns} dataSource={fetchDonors.data} />

      {/* CREATE DONOR MODAL  */}
      <Modal
        centered
        title='Add Donor'
        open={location.hash === '#add-new-donor'}
        onCancel={onModalCancel}
        footer={null}
      >
        <Form
          layout='vertical'
          form={form}
          onFinish={createDonor.mutate}
          style={{ marginBlock: '1rem' }}
        >
          <Form.Item name='donorName' label='Donor Name'>
            <Input placeholder="Please add Donor's name" />
          </Form.Item>

          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
            }}
          >
            <Button onClick={onModalCancel}>Cancel</Button>&nbsp;&nbsp;
            <Button type='primary' htmlType='submit'>
              Add Donor
            </Button>
          </div>
        </Form>
      </Modal>

      {/* EDIT DONOR MODAL */}
      <Modal
        centered
        title='Edit Donor'
        open={editRow !== null}
        onCancel={() => setEditRow(null)}
        footer={null}
      >
        <Form
          name='edit-donor'
          layout='vertical'
          form={editForm}
          onFinish={editDonor.mutate}
          style={{ marginBlock: 40 }}
        >
          <Form.Item label='Donor Name' name='donorName'>
            <Input placeholder='Enter Donor Name' />
          </Form.Item>

          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
            }}
          >
            <Button onClick={() => setEditRow(null)}>Cancel</Button>
            &nbsp;&nbsp;
            <Button type='primary' htmlType='submit'>
              Confirm Edit
            </Button>
          </div>
        </Form>
      </Modal>

      {/* DELETE DONOR MODAL */}
      <Modal
        centered
        title='Delete Donor'
        open={location.hash === '#delete-donor'}
        onCancel={onModalCancel}
        footer={null}
      >
        <Typography.Paragraph>
          Are you sure you want to delete this Donor?
        </Typography.Paragraph>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
          }}
        >
          <Button onClick={onModalCancel}>Cancel</Button>&nbsp;&nbsp;
          <Button danger>Delete</Button>
        </div>
      </Modal>
    </>
  );
};

export default DonorsList;
