import { CloseOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Card, Col, Form, Input, Row, Space, message } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';
import Request from '../../../libs/request';
import { useEffect } from 'react';

const WebhooksPage = () => {
  const [form] = useForm();
  const { orgId } = useSelector((store) => store.auth);
  const query = useQueryClient();

  const settingsFetch = useQuery(
    `org:${orgId}.settings`,
    async () => {
      const { settings } = await Request.get(`/organization/${orgId}/settings`);
      return settings || {};
    },
    {
      staleTime: 1000 * 60 * 10,
    }
  );
  const setFormInitialValues = (response) => {
    try {
      let endpoint = response.webhooks?.endpoint || '';
      let headers = response.webhooks?.headers || '';
      headers = Object.keys(headers).map((key) => {
        return { key: key, value: headers[key] };
      });
      // return { headers, endpoint };
      form.setFieldValue('endpoint', endpoint);
      form.setFieldValue('headers', headers);
    } catch (e) {}
  };

  useEffect(() => {
    setFormInitialValues(settingsFetch.data);
  }, [settingsFetch.data]); /* eslint-disable-line */

  const settingsUpdate = useMutation(
    (payload) => {
      payload.headers = payload.headers?.reduce((prev, current) => {
        prev[current.key] = current.value;
        return prev;
      }, {});
      const newSettings = settingsFetch.data; // {}
      newSettings['webhooks'] = payload;
      return Request.put(`/organization/${orgId}/settings`, newSettings);
    },
    {
      onSuccess: () => {
        message.success('Webhook Saved!');
        query.invalidateQueries(`org:${orgId}.settings`);
      },
      onError: () => {
        message.success('Webhook saving failed');
      },
    }
  );

  return (
    <Card
      title='Webhook'
      extra={[
        <Button
          loading={settingsUpdate.isLoading}
          type='primary'
          onClick={form.submit}
        >
          Save
        </Button>,
      ]}
    >
      <Row>
        <Col span={16}>
          <Form
            form={form}
            className='webhooks-form'
            layout='vertical'
            onFinish={settingsUpdate.mutate}
            initialValues={setFormInitialValues()}
          >
            <Form.Item
              label='Endpoint:'
              name='endpoint'
              rules={[
                { required: true, message: 'Endpoint is required' },
                {
                  pattern:
                    /^https:\/\/[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}(\/[^\s]*)?$/,
                  message: 'Invalid Endpoint',
                },
              ]}
            >
              <Input addonBefore='POST' />
            </Form.Item>
            <Form.Item label='Headers:'>
              <Form.List name='headers'>
                {(fields, { add, remove }) => (
                  <>
                    {fields.map(({ key, name, ...restField }) => (
                      <Space key={key} align='baseline' className='headers'>
                        <Form.Item
                          {...restField}
                          name={[name, 'key']}
                          style={{ marginBottom: '.5em' }}
                          rules={[
                            { required: true, message: 'Key is required' },
                          ]}
                        >
                          <Input placeholder='Key' />
                        </Form.Item>
                        <Form.Item
                          {...restField}
                          style={{ marginBottom: '.5em' }}
                          name={[name, 'value']}
                          rules={[
                            { required: true, message: 'Value is required' },
                          ]}
                        >
                          <Input placeholder='Value' />
                        </Form.Item>
                        <CloseOutlined onClick={() => remove(name)} />
                      </Space>
                    ))}
                    <Form.Item>
                      <Button
                        type='dashed'
                        style={{ textAlign: 'center' }}
                        onClick={() => add()}
                        icon={<PlusOutlined />}
                      >
                        Add Header
                      </Button>
                    </Form.Item>
                  </>
                )}
              </Form.List>
            </Form.Item>
          </Form>
        </Col>
        <Col></Col>
      </Row>
    </Card>
  );
};
export default WebhooksPage;
