import { PageHeader } from "@ant-design/pro-components";
import {
  Button,
  Col,
  Descriptions,
  Divider,
  Modal,
  Row,
  Space,
  Table,
} from "antd";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";
import moment from "moment";
import Request from "../../libs/request";

const RequestsPage = () => {
  const navigate = useNavigate();

  const { orgId } = useSelector((store) => store.auth);
  const [selected, setSelected] = useState(null);

  const requestsList = useQuery(`org:${orgId}.requests`, () => {
    return Request.get(`/organization/${orgId}/requests`);
  });

  return (
    <>
      <Space direction="vertical" style={{ width: "100%" }}>
        <PageHeader onBack={() => navigate(-1)} title="History" />
        <Table
          columns={[
            {
              title: "Timestamp",
              render: (row) =>
                moment(row.timestamp).format("YYYY-MM-DD HH:mm:ss"),
            },
            {
              title: "Cnic#",
              render: (row) => row?.ocr?.cnic || "---",
            },
            {
              title: "Name",
              render: (row) => row?.ocr?.name || "---",
            },
            {
              title: "Donor",
              render: (row) => row?.donor?.name,
            },
            {
              title: "Scanned By",
              render: (row) => (
                <Space direction="vertical">
                  <span>{row?.requestor?.name}</span>
                  <span>[{row?.requestor?.ip}]</span>
                </Space>
              ),
            },
            {
              title: "",
              render: (row) => (
                <Space direction="horizontal">
                  <Button onClick={() => setSelected(row)}>View</Button>
                </Space>
              ),
            },
          ]}
          loading={requestsList.isLoading}
          dataSource={requestsList.data}
        />
      </Space>

      {selected !== null && (
        <Modal
          open={true}
          onCancel={() => setSelected(null)}
          footer={null}
          width={"45%"}
        >
          <Descriptions>
            <Descriptions.Item label="CNIC#" span={6}>
              {selected?.ocr?.cnic || "---"}
            </Descriptions.Item>
            <Descriptions.Item label="Name" span={6}>
              {selected?.ocr?.name || "---"}
            </Descriptions.Item>
            <Descriptions.Item label="Father Name" span={6}>
              {selected?.ocr?.fatherName || "---"}
            </Descriptions.Item>
            <Descriptions.Item label="Gender" span={6}>
              {selected?.ocr?.gender || "---"}
            </Descriptions.Item>
            <Descriptions.Item label="Date of Birth" span={6}>
              {selected?.ocr?.dob || "---"}
            </Descriptions.Item>
            <Descriptions.Item label="Issue Date" span={6}>
              {selected?.ocr?.issueDate || "---"}
            </Descriptions.Item>
            <Descriptions.Item label="Expiry Date" span={6}>
              {selected?.ocr?.expiry || "---"}
            </Descriptions.Item>
          </Descriptions>

          <Row gutter={20}>
            <Col span={12}>
              <Divider orientation="left" plain>
                CNIC Front
              </Divider>
              <img
                src={selected?.assets?.front?.rectified}
                style={{ width: "100%", height: "15em", objectFit: "fill" }}
              />
            </Col>
            <Col span={12}>
              <Divider orientation="left" plain>
                CNIC Back
              </Divider>
              <img
                src={selected?.assets?.back?.rectified}
                style={{ width: "100%", height: "15em", objectFit: "fill" }}
              />
            </Col>
          </Row>
        </Modal>
      )}
    </>
  );
};

export default RequestsPage;
