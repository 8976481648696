import { useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, Outlet, useLocation, useNavigate } from "react-router-dom";
import { setAuthLogged } from "../store/reducers/auth";
import PageLoader from "../components/pageLoader";
import Request from "../libs/request";

const Entry = () => {
  window.navigate = useNavigate();
  const dispath = useDispatch();
  const location = useLocation();
  const { logged } = useSelector((store) => store.auth);
  const serachParmas = new URLSearchParams(location?.search);
  const token = localStorage.getItem("reid.auth.token");
  const initRequest = useQuery(
    "init",
    () => {
      return Request.get("/init");
    },
    {
      onSuccess: (resp) => {
        dispath(setAuthLogged(resp));
      },
      enabled:
        !serachParmas.has("unauthorized") &&
        !serachParmas.has("logout") &&
        token !== null,
      retry: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
    }
  );

  if (initRequest.isLoading) return <PageLoader />;
  else if ((!logged || !token) && location.pathname !== "/") {
    return (
      <Navigate to="/?unauthorized&message=Access Denied: Login is required" />
    );
  }

  return <Outlet />;
};
export default Entry;
