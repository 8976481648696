const Configs = {};

const environment = process.env.REACT_APP_ENV;
if (environment === 'production') {
  Configs.API_URL = 'https://3y9c1olatd.execute-api.eu-central-1.amazonaws.com';
} else if (environment === 'staging') {
  Configs.API_URL = 'https://kp9xwimi22.execute-api.us-east-1.amazonaws.com';
} else {
  Configs.API_URL = 'https://dxy3pzn0a5.execute-api.us-east-1.amazonaws.com';
}

module.exports = Configs;
