import React, { useState } from 'react';
import { PageHeader } from '@ant-design/pro-components';
import { useMutation, useQuery } from 'react-query';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button, Form, Input, Modal, Space, Table, message } from 'antd';
import { useForm } from 'antd/es/form/Form';
import Request from '../../libs/request';

const Admins = () => {
  const [editRow, setEditRow] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();

  const [form] = useForm();
  const [editForm] = useForm();

  // fetch all admins
  const fetchAdmins = useQuery('fetch-all-admins', () => {
    return Request.get('/super-admin');
  });

  // create an admin
  const createAdmin = useMutation((payload) => {
    return Request.post('/super-admin', payload);
  });

  // edit an admin
  const editAdmin = useMutation((payload) => {
    return Request.post(`/super-admin/${editRow?.adminCnic}`, payload);
  });

  // disable an admin
  const disableAdmin = useMutation(
    async (payload) => {
      return Request.post(`/super-admin/${payload.adminCnic}`);
    },
    {
      onError: () =>
        message.error(`Admin couldn't be disabled. Please try again`),
    },
    {
      onSuccess: (_, payload) => {
        message.success(`Admin '${payload.fullname}' Successfully disabled.`);
      },
    }
  );

  const columns = [
    {
      title: 'Fullname',
      dataIndex: 'fullname',
      key: 'fullname',
    },
    {
      title: 'Admin CNIC #',
      dataIndex: 'adminCnic',
      key: 'adminCnic',
    },
    {
      title: 'Added at',
      dataIndex: 'addedAt',
      key: 'addedAt',
    },
    {
      title: 'Actions',
      render: (row) => (
        <Space direction='horizontal'>
          <Button
            onClick={() => {
              setEditRow(row);
            }}
          >
            Edit
          </Button>
          <Button
            danger
            onClick={() => {
              Modal.confirm({
                centered: true,
                okText: 'Confirm Disable',
                okType: 'danger',
                title: 'Are you sure you want to disable this admin',
                onOk: () => disableAdmin.mutate(row),
              });
            }}
          >
            Disable
          </Button>
        </Space>
      ),
    },
  ];

  const onAdminFormCancel = () => navigate({ hash: '' });

  return (
    <>
      <PageHeader
        onBack={() => navigate(-1)}
        title='Admins'
        extra={[
          <Button
            type='primary'
            onClick={() => navigate({ hash: 'add-new-admin' })}
          >
            Add Admin
          </Button>,
        ]}
      />

      <Table dataSource={fetchAdmins.data} columns={columns} />

      {/* CREATE ADMIN MODAL  */}
      <Modal
        centered
        title='Add Admin'
        open={location.hash === '#add-new-admin'}
        onCancel={onAdminFormCancel}
        footer={null}
      >
        <Form
          name='admin'
          layout='vertical'
          form={form}
          onFinish={createAdmin.mutate}
          style={{ marginBlock: 40 }}
        >
          <Form.Item
            label='Fullname'
            name='adminFullname'
            rules={[{ required: true, message: "Please add the Admin's name" }]}
          >
            <Input placeholder='Enter Admin CNIC #' />
          </Form.Item>
          <Form.Item
            label='Admin CNIC #'
            name='adminCnic'
            rules={[{ required: true, message: 'Please add the CNIC #' }]}
          >
            <Input placeholder='Enter Admin CNIC #' />
          </Form.Item>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
            }}
          >
            <Button onClick={onAdminFormCancel}>Cancel</Button>
            &nbsp;&nbsp;
            <Button type='primary' htmlType='submit'>
              Add
            </Button>
          </div>
        </Form>
      </Modal>

      {/* EDIT ADMIN MODAL  */}
      <Modal
        centered
        title='Edit Admin'
        open={editRow !== null}
        onCancel={() => setEditRow(null)}
        footer={null}
      >
        <Form
          name='edit-admin'
          layout='vertical'
          form={editForm}
          initialValues={{
            fullname: editForm.fullname,
          }}
          onFinish={editAdmin.mutate}
          style={{ marginBlock: 40 }}
        >
          <Form.Item label='Fullname' name='adminFullname'>
            <Input placeholder='Enter Admin name' />
          </Form.Item>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
            }}
          >
            <Button onClick={() => setEditRow(null)}>Cancel</Button>
            &nbsp;&nbsp;
            <Button type='primary' htmlType='submit'>
              Edit
            </Button>
          </div>
        </Form>
      </Modal>
    </>
  );
};

export default Admins;
