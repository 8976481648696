import { useState } from 'react';
import {
  Button,
  Modal,
  Table,
  Form,
  Input,
  Space,
  Card,
  Typography,
  Row,
  Col,
} from 'antd';
import { PageHeader } from '@ant-design/pro-components';
import { useLocation, useNavigate } from 'react-router-dom';

import { useMutation, useQuery } from 'react-query';
import Request from '../../libs/request';
import { useForm } from 'antd/es/form/Form';

const OrganizationPage = () => {
  const [rowData, setRowData] = useState(null);

  const navigate = useNavigate();
  const location = useLocation();

  const [form] = useForm();
  const [editForm] = useForm();

  // LIST OF ORGANIZATIONS
  const orgsList = useQuery('organizations', () => {
    return Request.get(`/super-admin/orgs`);
  });

  // ADD NEW ORGANIZATION THROUGH THE MODAL
  const addOrgs = useMutation((payload) => {
    return Request.post(`/super-admin/orgs`, payload);
  });

  // INDIVIDUAL ORGANIZATION STATS
  const orgStats = useMutation((orgId) => {
    return Request.post(`/super-admin/orgs/${orgId}/stats`);
  });

  // EDIT A SPECIFIC ORGANIZATION
  const editOrg = useMutation((payload) => {
    return Request.post(`/super-admin/orgs/${rowData.orgId}`, payload);
  });

  // DISABLE AN ORGANIZATION
  const disableOrg = useMutation((payload) => {
    return Request.post(`/super-admin/orgs/${payload.orgId}`);
  });

  const statFields = [
    { title: 'Success', stats: orgStats?.data?.success ?? 0 },
    { title: 'Failed', stats: orgStats?.data?.failed ?? 0 },
    { title: 'Total', stats: orgStats?.data?.total ?? 0 },
  ];

  const columns = [
    {
      title: 'Organization Name',
      dataIndex: 'organizationName',
      key: 'organizationName',
    },
    {
      title: 'Initial Admin',
      dataIndex: 'initialAdmin',
      key: 'initialAdmin',
    },
    {
      title: 'Actions',
      render: (row) => (
        <Space direction='horizontal'>
          <Button
            onClick={() => {
              navigate({ hash: 'org-stats' });
              orgStats.mutate(row.orgId);
            }}
          >
            Stats
          </Button>
          <Button
            onClick={() => {
              navigate({ hash: 'edit-org' });
              setRowData(row);
            }}
          >
            Edit
          </Button>
          <Button
            danger
            onClick={() => {
              Modal.confirm({
                centered: true,
                okText: 'Confirm Disable',
                okType: 'danger',
                title: 'Are you sure you want to disable this organization?',
                onOk: () => disableOrg.mutate(row),
              });
            }}
          >
            Disable
          </Button>
        </Space>
      ),
    },
  ];

  const onModalCancel = () => navigate({ hash: '' });

  return (
    <>
      <PageHeader
        onBack={() => navigate(-1)}
        title='Organizations'
        extra={[
          <Button
            type='primary'
            onClick={() => {
              navigate({ hash: 'create-org' });
            }}
          >
            Add Organization
          </Button>,
        ]}
      />
      <Table dataSource={orgsList.data} columns={columns} />

      {/* STATS MODAL  */}
      <Modal
        centered
        title='Stats'
        open={location.hash === '#org-stats'}
        onCancel={onModalCancel}
        footer={null}
      >
        <Row style={{ marginBottom: '1rem' }}>
          {statFields.map((field) => (
            <Col
              key={field.title}
              style={{ padding: 5 }}
              span={field.title === 'Total' ? 24 : 12}
            >
              <Card>
                <Typography.Title level={4} style={{ margin: 0 }}>
                  {field.title}
                </Typography.Title>
                <Typography.Paragraph>{field.stats}</Typography.Paragraph>
              </Card>
            </Col>
          ))}
        </Row>
      </Modal>

      {/* ADD ORG MODAL  */}
      <Modal
        centered
        title='Add Organization'
        open={location.hash === '#create-org'}
        onCancel={onModalCancel}
        footer={null}
      >
        <Form
          name='organization'
          layout='vertical'
          form={form}
          onFinish={addOrgs.mutate}
          style={{ marginBlock: 40 }}
        >
          <Form.Item
            label='Organization Name'
            name='organizationName'
            rules={[{ required: true, message: 'Please add the organization' }]}
          >
            <Input placeholder='Enter Organization Name' />
          </Form.Item>
          <Form.Item
            label='CNIC #'
            name='cnicNumber'
            rules={[
              { required: true, message: 'Please Enter the CNIC Number' },
              {
                pattern: /^(?:\d*)$/,
                message: 'Value should contain just number',
              },
              {
                minLength: 13,
                message: 'Value should not be less than 13 character',
              },
            ]}
          >
            <Input maxLength={13} placeholder='Enter CNIC Number' />
          </Form.Item>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
            }}
          >
            <Button onClick={onModalCancel}>Cancel</Button>
            &nbsp;&nbsp;
            <Button type='primary' htmlType='submit'>
              Add
            </Button>
          </div>
        </Form>
      </Modal>

      {/* EDIT ORGANIZATION MODAL  */}
      <Modal
        centered
        title='Edit Organization'
        open={location.hash === '#edit-org'}
        onCancel={() => {
          onModalCancel();
          setRowData(null);
        }}
        footer={null}
      >
        <Form
          name='edit-organization'
          layout='vertical'
          form={editForm}
          onFinish={editOrg.mutate}
          style={{ marginBlock: 40 }}
        >
          <Form.Item label='Organization Name' name='organizationName'>
            <Input placeholder='Enter Organization Name' />
          </Form.Item>
          <Form.Item label='Initial Admin' name='initialAdmin'>
            <Input placeholder='Enter Admin CNIC' />
          </Form.Item>

          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
            }}
          >
            <Button onClick={() => setRowData(null)}>Cancel</Button>
            &nbsp;&nbsp;
            <Button
              type='primary'
              htmlType='submit'
              loading={editOrg.isLoading}
            >
              Confirm Edit
            </Button>
          </div>
        </Form>
      </Modal>
    </>
  );
};

export default OrganizationPage;
