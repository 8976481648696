import { Button, Card, Form, Input, Modal, Table, message } from "antd";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { useForm } from "antd/es/form/Form";
import moment from "moment";
import Request from "../../../libs/request";

const DonorsPage = () => {
  const { orgId } = useSelector((store) => store.auth);
  const location = useLocation();
  const navigate = useNavigate();
  const [addForm] = useForm();
  const query = useQueryClient();

  const donorsList = useQuery(
    `org:${orgId}.doners`,
    () => {
      return Request.get(`/organization/${orgId}/donors`);
    },
    {
      staleTime: 1000 * 60 * 10,
    }
  );

  const donersAdd = useMutation(
    (payload) => {
      return Request.post(`/organization/${orgId}/donors`, payload);
    },
    {
      onSuccess: (resp, payload) => {
        message.success(`Donor '${payload.donorName}' added!`);
        query.invalidateQueries(`org:${orgId}.doners`);
        _onModalCancel();
      },
      onError: (resp, payload) => {
        message.error(
          resp?.response?.data?.message ||
            `Donor '${payload.donorName}' adding failed!`
        );
      },
    }
  );

  const _onModalCancel = () => {
    addForm.resetFields();
    navigate({ hash: "" });
  };

  return (
    <Card
      title="Donors"
      extra={[
        <Button type="primary" onClick={() => navigate({ hash: "add-new" })}>
          Add New
        </Button>,
      ]}
    >
      <Table
        columns={[
          {
            title: "Created At",
            render: (row) => moment(row.addTs).format("YYYY-MM-DD HH:mm:ss"),
            width: "15em",
          },
          {
            title: "Name",
            dataIndex: "name",
          },
        ]}
        dataSource={donorsList.data}
        loading={donorsList.isLoading}
      />

      <Modal
        title="Add new Doner"
        onCancel={_onModalCancel}
        open={location.hash == "#add-new"}
        footer={null}
        // okButtonProps={{ onClick: addForm.submit }}
        centered
      >
        <Form form={addForm} layout="vertical" onFinish={donersAdd.mutate}>
          <Form.Item
            name="donorName"
            label="Name"
            rules={[{ required: true, message: "Name is required" }]}
          >
            <Input />
          </Form.Item>

          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <Button
              loading={donersAdd.isLoading}
              htmlType="submit"
              type="primary"
            >
              Add
            </Button>
          </div>
        </Form>
      </Modal>
    </Card>
  );
};
export default DonorsPage;
