import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  logged: null,
  orgId: null,
  isAdmin: false,
};

const AuthSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAuthLogged: (state, action) => {
      state.orgId = action.payload?.orgs[0].id;
      state.isAdmin = action.payload?.orgs[0].isAdmin;
      state.logged = action.payload;
    },
  },
});

export const { setAuthLogged } = AuthSlice.actions;
export default AuthSlice.reducer;
