import { PageLoading } from "@ant-design/pro-components";

const PageLoader = () => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
        width: "100vw",
      }}
    >
      <PageLoading />
    </div>
  );
};
export default PageLoader;
