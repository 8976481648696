import { PageHeader } from "@ant-design/pro-components";
import { Col, Menu, Modal, Row } from "antd";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import UsersPage from "./users.page";
import WebhooksPage from "./webhooks.page";
import IpWhiteListPage from "./ipWhitelist.page";
import DonorsPage from "./donors.page";
import { useMutation } from "react-query";
import Request from "../../../libs/request";

const SettingsPage = () => {
  const navigate = useNavigate();
  return (
    <>
      <PageHeader title="Settings" />
      <Row gutter={10}>
        <Col span={6}>
          <Menu
            mode="inline"
            style={{
              width: "100%",
              overflow: "hidden",
              borderRadius: "var(--roundness)",
              paddingBlock: "1em",
              border: "1px solid #f0f0f0",
            }}
            onSelect={({ key }) => navigate(key)}
            items={[
              { key: "donors", label: "Donors" },
              { type: "group", label: "Access" },
              { key: "users", label: "Users" },
              { key: "ip-whitelist", label: "IP Whitelist" },
              { type: "group", label: "Integration" },
              { key: "webhooks", label: "Webhook" },
            ]}
          />
        </Col>
        <Col span={18}>
          <Routes>
            <Route index element={<Navigate to="users" />} />
            <Route path="donors" element={<DonorsPage />} />
            <Route path="users" element={<UsersPage />} />
            <Route path="webhooks" element={<WebhooksPage />} />
            <Route path="ip-whitelist" element={<IpWhiteListPage />} />
          </Routes>
        </Col>
      </Row>
    </>
  );
};
export default SettingsPage;
