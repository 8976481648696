import React from "react";
import { Avatar, Dropdown, Menu, Modal, message } from "antd";
import { ExclamationCircleOutlined, LogoutOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";

const LogoutUser = () => {
  const { logged } = useSelector((store) => store.auth);

  const onLogout = () => {
    localStorage.removeItem("reid.auth.token");
    window.location.href = "/?logout";
  };

  return (
    <>
      <Dropdown
        overlay={
          <Menu>
            <Menu.Item
              onClick={() =>
                Modal.confirm({
                  centered: true,
                  icon: <ExclamationCircleOutlined />,
                  cancelText: "No",
                  okText: "Yes",
                  title: "Are you sure  want to logout?",
                  onOk: onLogout,
                })
              }
              key="logout"
              icon={<LogoutOutlined />}
            >
              Logout
            </Menu.Item>
          </Menu>
        }
        placement="bottomRight"
        arrow
      >
        <div style={{ cursor: "pointer" }}>
          <Avatar>{logged?.name[0]}</Avatar>
          <span style={{ marginLeft: 8 }}>{logged?.name}</span>
        </div>
      </Dropdown>
    </>
  );
};

export default LogoutUser;
