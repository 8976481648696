import {
  CameraOutlined,
  DeleteOutlined,
  UploadOutlined,
} from '@ant-design/icons';
import { Button, Card, Select } from 'antd';
import axios from 'axios';
import React, { useEffect, useImperativeHandle, useRef, useState } from 'react';
import { useMutation } from 'react-query';
import Request from '../libs/request';

const CameraCapture = React.forwardRef(
  ({ title, onChange, devices, id, error, result }, ref) => {
    const videoRef = useRef();
    const [captured, setCaptured] = useState(null);
    const [videoStream, setVideoStream] = useState(null);

    const [selectedDevice, setSelectedDevice] = useState(
      window.localStorage.getItem(`reid.${id}.deviceId`) || null
    );

    const getDeviceStream = async () => {
      const stream = await navigator.mediaDevices.getUserMedia({
        audio: false,
        video: {
          deviceId: selectedDevice,
        },
      });
      if (videoRef.current && stream) {
        videoRef.current.srcObject = stream;
        setVideoStream(stream);
      }
    };

    useEffect(() => {
      getDeviceStream();
    }, [selectedDevice]);

    /// Capture From Camera
    const _capture = async () => {
      let canvas = document.createElement('canvas');
      let ctx = canvas.getContext('2d');
      canvas.width = videoRef.current.videoWidth;
      canvas.height = videoRef.current.videoHeight; // Corrected line
      ctx.drawImage(videoRef.current, 0, 0, canvas.width, canvas.height);
      const image = canvas.toDataURL('image/jpeg');
      setCaptured(image);
    };

    // Upload Image to S3
    const _upload = useMutation(async () => {
      const signedUrl = await Request.get('/get-sign-url');
      const payload = new FormData();
      Object.keys(signedUrl.fields).forEach((key) => {
        payload.append(key, signedUrl.fields[key]);
      });
      /// Convert Base64 to File
      const cleanedBase64String = captured.replace(
        /^data:image\/[a-z]+;base64,/,
        ''
      );
      const byteCharacters = atob(cleanedBase64String);
      const byteNumbers = Array.from(byteCharacters, (ch) => ch.charCodeAt(0));
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: 'image/jpeg' });
      const file = new File([blob], 'filename.jpg', { type: 'image/jpeg' });
      payload.append('file', file);

      /// Now Upload File
      const { data } = await axios.post(signedUrl.url, payload, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      onChange({ key: signedUrl.fields.Key });
      return data;
    });

    /// Delete Capture Image
    const _delete = () => {
      onChange({ key: null });
      _upload.reset();
      setCaptured(null);
    };

    const onDeviceSelect = (deviceId) => {
      setSelectedDevice(deviceId);
      localStorage.setItem(`reid.${id}.deviceId`, deviceId);
    };

    useImperativeHandle(
      ref,
      () => {
        return {
          reset: () => _delete(),
        };
      },
      []
    );

    let errroMessage = 'test';

    return (
      <Card
        title={title}
        className='camera-capture'
        extra={[
          <Select
            style={{ width: '10em' }}
            onSelect={onDeviceSelect}
            defaultValue={selectedDevice}
            options={devices.map((e) => {
              return { value: e.deviceId, label: e.label };
            })}
          />,
        ]}
        actions={[
          <Button
            onClick={_delete}
            icon={<DeleteOutlined />}
            type='text'
            disabled={!captured}
            danger
          >
            Delete
          </Button>,
          <Button
            disabled={
              _upload.isError || _upload.isSuccess || captured || !videoStream
            }
            icon={<CameraOutlined />}
            onClick={_capture}
            type='text'
          >
            Capture
          </Button>,
          <Button
            disabled={!captured || _upload.isSuccess}
            onClick={_upload.mutate}
            type='text'
            loading={_upload.isLoading}
            icon={<UploadOutlined />}
          >
            Upload
          </Button>,
        ]}
      >
        <video ref={videoRef} autoPlay={true} />
        {captured && <img className='captured' src={captured} />}
        {error && <div className='error'>{error}</div>}
      </Card>
    );
  }
);
export default CameraCapture;
