import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  new: {},
};
const VerificationSlice = createSlice({
  name: "verification",
  initialState,
  reducers: {
    setVerficationNew: (state, action) => {
      state.new = action.payload;
    },
    updateVerficationNew: (state, action) => {
      state.new = { ...state.new, ...action.payload };
    },
  },
});

export const { setVerficationNew, updateVerficationNew } =
  VerificationSlice.actions;
export default VerificationSlice.reducer;
