import axios from "axios";
import CameraCapture from "../../components/cameraCapture";
import { useEffect, useRef, useState } from "react";
import { CopyBlock, dracula } from "react-code-blocks";

import {
  Button,
  Card,
  Col,
  Descriptions,
  Divider,
  Form,
  Result,
  Row,
  Select,
  Space,
  Typography,
  message,
} from "antd";
import { useMutation, useQuery } from "react-query";
import { PageHeader } from "@ant-design/pro-components";
import { SendOutlined, UndoOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import Request from "../../libs/request";
import { useForm } from "antd/lib/form/Form";
import _ from "lodash";
import useLocalStorage from "use-local-storage";

const ScanPage = () => {
  const [images, setImages] = useState({ front: false, back: false });
  const [tab, setTab] = useState("basic");
  const { orgId } = useSelector((store) => store.auth);
  const [form] = useForm();
  const [donorId, setDonorId] = useLocalStorage("reid.donor.selected");

  const [errors, setErrors] = useState({
    front: null,
    back: null,
  });

  const frontRef = useRef();
  const backRef = useRef();

  const donorsList = useQuery(
    `org:${orgId}.doners`,
    () => {
      return Request.get(`/organization/${orgId}/donors`);
    },
    {
      staleTime: 1000 * 60 * 10,
    }
  );

  useEffect(() => {
    if (
      donorId &&
      !donorsList.isLoading &&
      _.find(donorsList.data, { id: donorId })
    ) {
      form.setFieldValue("donor", donorId);
    }
  }, [donorsList.data]);

  const [devicesList, setDevicesList] = useState([]);
  const fetchStreamsList = async () => {
    const list = await navigator.mediaDevices.enumerateDevices();
    setDevicesList(list.filter((device) => device.kind === "videoinput") || []);
  };

  const canSubmit = () => {};

  useEffect(() => {
    fetchStreamsList();
  }, []);

  const cnicDetail = useMutation(
    async () => {
      return Request.post(`/organization/${orgId}/requests`, {
        ...images,
        donor: donorId,
      });
    },
    {
      onSuccess: (data) => {
        const errors = { front: null, back: null };
        if (!data?.assets?.back)
          errors["back"] = "Unable to Process, Capture Again";
        if (!data?.assets?.front)
          errors["front"] = "Unable to Process, Capture Again";

        if (
          data?.assets?.front &&
          data?.assets?.back &&
          (!data?.ocr || !Object.keys(data?.ocr).length)
        )
          errors["front"] = "OCR Failed: Provide a clear image";

        setErrors(errors);

        if (errors.back || errors.front) throw Error("Restriction Failed");
      },
      onError: () => {},
    }
  );

  const onReset = () => {
    setImages({ front: false, back: false });
    setErrors({ front: null, back: null });
    frontRef.current.reset();
    backRef.current.reset();
    cnicDetail.reset();
  };

  const frontRectified = cnicDetail?.data?.assets?.front?.rectified;
  const backRectified = cnicDetail?.data?.assets?.back?.rectified;
  const ocrData =
    cnicDetail?.data?.ocr && Object.keys(cnicDetail?.data?.ocr).length
      ? cnicDetail?.data?.ocr
      : null;

  return (
    <Space direction="vertical">
      <PageHeader
        title="Scan"
        extra={[
          <Form
            layout="inline"
            form={form}
            initialValues={{
              donor: null,
            }}
          >
            <Form.Item name="donor" label="Donor" valuePropName="value">
              <Select
                loading={donorsList.isLoading}
                style={{ width: "15em" }}
                disabled={donorsList.isLoading}
                onSelect={setDonorId}
                placeholder="Select a Donor"
                options={donorsList?.data?.map((entry) => {
                  return { value: entry.id, label: entry.name };
                })}
              />
            </Form.Item>
          </Form>,
        ]}
      />

      <Row gutter={[0, 0]}>
        <Col xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 12 }}>
          <CameraCapture
            ref={frontRef}
            id="cnicFront"
            title="Cnic Front"
            devices={devicesList}
            onChange={({ key }) => {
              setErrors((prev) => {
                return { ...errors, front: null };
              });
              setImages((prev) => ({ ...prev, front: key }));
            }}
            error={errors.front}
          />
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 12 }}>
          <CameraCapture
            ref={backRef}
            id="cnicBack"
            title="Cnic Back"
            devices={devicesList}
            onChange={({ key }) => {
              setErrors(() => {
                return { ...errors, back: null };
              });
              setImages((prev) => ({ ...prev, back: key }));
            }}
            error={errors.back}
          />
        </Col>
      </Row>
      <div
        style={{
          display: "flex",
          marginBlock: "1em",
          alignItems: "center",
          justifyContent: "end",
        }}
      >
        <Space
          direction="horizontal"
          size={5}
          style={{ justifyContent: "end" }}
        >
          <Button onClick={onReset} icon={<UndoOutlined />}>
            Reset
          </Button>
          <Button
            onClick={cnicDetail.mutate}
            loading={cnicDetail.isLoading}
            disabled={
              !images.front ||
              !images.back ||
              !donorId ||
              errors.back !== null ||
              errors.front !== null ||
              cnicDetail.isSuccess
            }
            icon={<SendOutlined />}
            type="primary"
          >
            Submit
          </Button>
        </Space>
      </div>

      {cnicDetail.isSuccess && (
        <Card
          title="Result"
          activeTabKey={tab}
          onTabChange={setTab}
          tabList={[
            {
              key: "basic",
              tab: "Basic",
            },
            {
              key: "json",
              tab: "JSON",
            },
          ]}
        >
          {tab == "json" && (
            <CopyBlock
              text={
                cnicDetail?.data
                  ? JSON.stringify(cnicDetail?.data, null, 2)
                  : ""
              }
              language="json"
              showLineNumbers={false}
              theme={dracula}
              codeBlock
            />
          )}
          {tab == "basic" && (
            <>
              <Descriptions
                column={{ xxl: 4, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }}
              >
                <Descriptions.Item label="Cnic#">
                  {ocrData?.cnic || "---"}
                </Descriptions.Item>
                <Descriptions.Item label="Full Name">
                  {ocrData?.name || "---"}
                </Descriptions.Item>
                <Descriptions.Item label="Father Name">
                  {ocrData?.fatherName || "---"}
                </Descriptions.Item>
                <Descriptions.Item label="Date of Birth">
                  {ocrData?.dob || "---"}
                </Descriptions.Item>
                <Descriptions.Item label="Issue Date">
                  {ocrData?.issueDate || "---"}
                </Descriptions.Item>
                <Descriptions.Item label="Expire Date">
                  {ocrData?.expiry || "---"}
                </Descriptions.Item>
              </Descriptions>
              <Divider dashed orientation="left">
                Rectified Images
              </Divider>
              <Row gutter={[20, 20]}>
                <Col xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 12 }}>
                  <img style={{ width: "100%" }} src={frontRectified} />
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 12 }}>
                  <img style={{ width: "100%" }} src={backRectified} />
                </Col>
              </Row>
            </>
          )}
        </Card>
      )}
    </Space>
  );
};

export default ScanPage;
