import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { PageHeader } from '@ant-design/pro-components';
import {
  Button,
  Card,
  Form,
  Input,
  Modal,
  Space,
  Switch,
  Table,
  Typography,
  message,
} from 'antd';
import { useForm } from 'antd/es/form/Form';
import { useLocation, useNavigate } from 'react-router-dom';
import Regexes from '../../../constants/regex';
import { useSelector } from 'react-redux';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import moment from 'moment';
import Request from '../../../libs/request';

const UsersPage = () => {
  const { orgId } = useSelector((store) => store.auth);
  const navigate = useNavigate();
  const location = useLocation();
  const [form] = useForm();
  const query = useQueryClient();

  const usersList = useQuery(
    `org:${orgId}.users`,
    async () => {
      return Request.get(`/organization/${orgId}/users`);
    },
    {
      staleTime: 1000 * 60 * 10,
    }
  );

  const usersAdd = useMutation(
    async (payload) => {
      return Request.post(`/organization/${orgId}/users`, payload);
    },
    {
      onError: ({ response }) =>
        message.error(response?.data?.message || `User adding failed`),
      onSuccess: (resp, payload) => {
        message.success(`User '${payload.fullName}' Added`);
        query.invalidateQueries(`org:${orgId}.users`);
        onFormCancel();
      },
    }
  );

  const usersDelete = useMutation(
    async (payload) => {
      return Request.delete(`/organization/${orgId}/users/${payload.userId}`);
    },
    {
      onError: ({ response }) => {
        message.error(response?.data?.message || `User Deleting failed`);
        usersDelete.reset();
      },
      onSuccess: (resp, payload) => {
        message.success(`User '${payload.fullName}' Deleted`);
        usersDelete.reset();
        query.invalidateQueries(`org:${orgId}.users`);
        onFormCancel();
      },
    }
  );

  const onFormCancel = () => {
    form.resetFields();
    navigate({ hash: '' });
  };

  return (
    <Card
      title='Users'
      extra={[
        <Button
          onClick={() => navigate({ hash: 'add-new' })}
          type='primary'
          icon={<PlusOutlined />}
        >
          Add New
        </Button>,
      ]}
    >
      <Space direction='vertical'>
        <Table
          dataSource={usersList.data}
          loading={usersList.isLoading}
          columns={[
            { title: 'CNIC#', dataIndex: 'userId' },
            { title: 'Full Name', dataIndex: 'fullName' },
            {
              title: 'Created At',
              render: (row) =>
                moment(row.createdAt).format('YYYY-MM-DD HH:mm:ss'),
            },
            {
              title: 'Role',
              render: (row) => (row.isAdmin ? 'Admin' : 'User'),
            },
            {
              title: 'Actions',
              render: (row) => (
                <Space direction='horizontal'>
                  <Button
                    onClick={() => usersDelete.mutate(row)}
                    loading={usersDelete?.variables?.userId == row.userId}
                    type='link'
                    danger
                    icon={<DeleteOutlined />}
                  >
                    Delete
                  </Button>
                </Space>
              ),
            },
          ]}
        />
      </Space>

      <Modal
        title='Add new User'
        centered
        open={location.hash == '#add-new'}
        style={{ padding: '1rem' }}
        footer={null}
        onCancel={onFormCancel}
      >
        <Form
          layout='vertical'
          onFinish={usersAdd.mutate}
          form={form}
          // initialValues={{
          //   // fullName: editUser ? editUser.user.fullName : "",
          //   // cnic: editUser ? editUser.user.cnic : "",
          //   // groups: editUser ? editUser.groups.map((e) => e._id) : [],
          // }}
          style={{ paddingBlock: '1rem' }}
        >
          <Form.Item
            name='user'
            label='CNIC#'
            rules={[
              { required: true, message: 'CNIC Number is required' },
              {
                pattern: Regexes.cnicWithoutDashes,
                message: 'Invalid CNIC Number',
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name='fullName'
            label='Full Name'
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>
          <Form.Item name='isAdmin' label='Is Admin?'>
            <Switch />
          </Form.Item>

          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
            }}
          >
            <Button onClick={onFormCancel}>Cancel</Button>
            &nbsp;&nbsp;
            <Button
              type='primary'
              loading={usersAdd.isLoading}
              // loading={editUser ? userUpdate.isLoading : usersAdd.isLoading}
              htmlType='submit'
            >
              Add
            </Button>
          </div>
        </Form>
      </Modal>
    </Card>
  );
};

export default UsersPage;
