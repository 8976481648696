import {
  CameraOutlined,
  ExclamationCircleOutlined,
  FileSearchOutlined,
  LogoutOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import {
  Avatar,
  Button,
  Dropdown,
  Layout,
  Menu,
  Modal,
  Space,
  Typography,
  message,
} from "antd";
import { Content, Header } from "antd/es/layout/layout";
import { Navigate, Route, Routes } from "react-router-dom";
import { useSelector } from "react-redux";
import ScanPage from "./scan.page";
import RequestsPage from "./requests.page";
import LogoutUser from "../../components/logoutUser";
import SettingsPage from "./settings";

const AppHandler = () => {
  const {
    isAdmin,
    logged: { orgs },
  } = useSelector((store) => store.auth);

  return (
    <Layout
      style={{ height: "100vh", display: "flex", flexDirection: "column" }}
    >
      <Header
        style={{
          display: "flex",
          alignItems: "center",
          paddingInline: "1%",
          background: "#fff",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "stretch",
            justifyContent: "space-between",
          }}
        >
          <img className="logo" src="/reid-logo.svg" />
          <span
            style={{
              background: "rgb(245 245 245)",
              width: "2px",
              marginInline: "1em",
            }}
          />
          <Typography.Text
            style={{ marginBlock: "1em", fontSize: "16px", fontWeight: "600" }}
          >
            {orgs[0]?.name}
          </Typography.Text>
        </div>

        <Menu
          style={{ width: "fit-content" }}
          theme="light"
          inlineCollapsed={false}
          mode="horizontal"
          defaultSelectedKeys={["0"]}
          onSelect={({ key }) => window.navigate(key)}
        >
          <Menu.Item key="/app/scan" icon={<CameraOutlined />}>
            Scan
          </Menu.Item>
          <Menu.Item key="/app/history" icon={<FileSearchOutlined />}>
            History
          </Menu.Item>
          {isAdmin == true && (
            <Menu.Item key="/app/settings" icon={<SettingOutlined />}>
              Settings
            </Menu.Item>
          )}
        </Menu>
        <LogoutUser />
      </Header>
      <Content
        style={{
          flex: 1,
          overflowY: "scroll",
          padding: "1em var(--window-offset)",
        }}
      >
        <Routes>
          <Route index element={<Navigate to="scan" />}></Route>
          <Route path="/scan" element={<ScanPage />} />
          <Route path="/history" element={<RequestsPage />} />
          <Route path="/settings/*" element={<SettingsPage />} />
        </Routes>
      </Content>
    </Layout>
  );
};
export default AppHandler;
