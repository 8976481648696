import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Store from './store';
import { Provider } from 'react-redux';
import { QueryClient, QueryClientProvider } from 'react-query';

import './style.css';
import HomePage from './pages/home.page';
import AppHandler from './pages/app/_handler';
import DashboardHandler from './pages/dashboard/_handler';
import Entry from './pages/entry';
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnMount: true,
      refetchOnReconnect: true,
      refetchOnWindowFocus: false,
    },
  },
});

const App = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <Provider store={Store}>
        <Router>
          <Routes>
            <Route path='*' element={<Entry />}>
              <Route index element={<HomePage />} />
              <Route path='app/*' element={<AppHandler />} />
              <Route path='dashboard/*' element={<DashboardHandler />} />
            </Route>
          </Routes>
        </Router>
      </Provider>
    </QueryClientProvider>
  );
};
export default App;
