import axios from "axios";
import Configs from "../configs";
import { message } from "antd";
const Request = axios.create({});

const errorMessage = {
  INVALID_TOKEN: "Access Denied: Invalid Token",
  UN_AUTHORIZED: "Access Denied: Unauthorized",
  ORG_NOT_FOUND: "Access Denied: Organization not Found",
  IP_NOT_PERMITTED: "Access Denied: IP not Permitted",
};

Request.interceptors.request.use(
  async (config) => {
    const token = localStorage.getItem("reid.auth.token");
    config.baseURL = Configs.API_URL;
    config.headers = {
      Authorization: `Bearer ${token}`,
    };
    return config;
  },
  (error) => Promise.reject(error)
);
Request.interceptors.response.use(
  function (response) {
    return response.data;
  },
  function (error) {
    if (error?.response?.status == 401) {
      const { hintCode, message } = error?.response?.data;
      localStorage.removeItem("reid.auth.token");
      window.location.href = `/?unauthorized&message=${message}`;
    }
    return Promise.reject(error);
  }
);

export default Request;
