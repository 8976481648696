import { Col, Layout, Menu, Row } from 'antd';
import { Route, Routes, useNavigate } from 'react-router-dom';
import OrganizationPage from './organization.page';
import DonorsList from './donorsList.page';
import Admins from './admins.page';
import Sider from 'antd/es/layout/Sider';
import { Content, Header } from 'antd/es/layout/layout';
import LogoutUser from '../../components/logoutUser';

const DashboardHandler = () => {
  const navigate = useNavigate();

  const SIDEBAR_ITEMS = [
    { key: 'organizations', label: 'Organizations' },
    { key: 'donorsList', label: 'Donors List' },
    { type: 'group', label: 'Access' },
    { key: 'admins', label: 'Admins' },
  ];

  return (
    <Layout hasSider style={{ height: '100vh' }}>
      <Sider
        style={{
          overflow: 'auto',
          height: '100vh',
          position: 'fixed',
          left: 0,
          top: 0,
          bottom: 0,
        }}
      >
        <div className='demo-logo-vertical' />
        <Menu
          mode='inline'
          items={SIDEBAR_ITEMS}
          onSelect={({ key }) => navigate(key)}
          style={{
            width: '100%',
            height: '100%',
            overflow: 'hidden',
            borderRadius: '0px',
            paddingBlock: '1em',
            border: '1px solid #f0f0f0',
          }}
        />
      </Sider>

      <Layout
        className='site-layout'
        style={{
          marginLeft: 200,
        }}
      >
        <Header
          style={{
            padding: 0,
            background: '#fff',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <div style={{ flex: 1 }} />
          <div style={{ marginRight: '1rem' }}>
            <LogoutUser />
          </div>
        </Header>

        <Content
          style={{
            margin: '24px 16px 0',
            overflow: 'initial',
          }}
        >
          <Row justify='center'>
            <Col span={18}>
              <Routes>
                <Route path='' index element={<h1>Dashboard</h1>} />
                <Route path='organizations' element={<OrganizationPage />} />
                <Route path='donorsList' element={<DonorsList />} />
                <Route path='admins' element={<Admins />} />
              </Routes>
            </Col>
          </Row>
        </Content>
      </Layout>
    </Layout>
  );
};

export default DashboardHandler;
