import { CloseOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Card, Form, Input, Space, message } from "antd";
import { useForm } from "antd/es/form/Form";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useSelector } from "react-redux";
import Request from "../../../libs/request";
import { useEffect } from "react";

const IpWhiteListPage = () => {
  const [form] = useForm();
  const { orgId } = useSelector((store) => store.auth);

  const query = useQueryClient();

  const settingsFetch = useQuery(
    `org:${orgId}.settings`,
    async () => {
      const { settings } = await Request.get(`/organization/${orgId}/settings`);
      return settings || {};
    },
    {
      staleTime: 1000 * 60 * 10,
    }
  );

  useEffect(() => {
    form.setFieldValue("whitelistedIPs", settingsFetch?.data?.whitelistedIPs);
  }, [settingsFetch.data]);

  const settingsUpdate = useMutation(
    (payload) => {
      const newSettings = settingsFetch.data;
      newSettings["whitelistedIPs"] = payload.whitelistedIPs;
      return Request.put(`/organization/${orgId}/settings`, newSettings);
    },
    {
      onSuccess: () => {
        message.success("IP Whitelist saved!");
        query.invalidateQueries(`org:${orgId}.settings`);
      },
      onError: () => {
        message.success("IP Whitelist saving failed");
      },
    }
  );

  return (
    <Card
      title="IP Whitelist"
      extra={[
        <Button
          loading={settingsUpdate.isLoading}
          onClick={form.submit}
          type="primary"
        >
          Save
        </Button>,
      ]}
    >
      <Form
        onFinish={settingsUpdate.mutate}
        form={form}
        className="ipwhitelist-form"
      >
        <Form.List name="whitelistedIPs">
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, ...restField }) => (
                <Space direction="horizontal">
                  <Form.Item
                    {...restField}
                    name={[name]}
                    style={{ marginBottom: "1em", flex: 1 }}
                    rules={[
                      { required: true, message: "IP is required" },
                      {
                        pattern:
                          /^(?!.*\.$)((?!0\d)(1?\d?\d|25[0-5]|2[0-4]\d)(\.|$)){4}$/,
                        message: "Invalid IP address",
                      },
                    ]}
                  >
                    <Input placeholder="IP Address" />
                  </Form.Item>
                  <CloseOutlined onClick={() => remove(name)} />
                </Space>
              ))}
              <Form.Item>
                <Button
                  type="dashed"
                  style={{ textAlign: "center" }}
                  onClick={() => add()}
                  icon={<PlusOutlined />}
                >
                  Add New IP
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
      </Form>
    </Card>
  );
};
export default IpWhiteListPage;
