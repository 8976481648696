import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Layout,
  Menu,
  Row,
  Space,
  Typography,
  message,
} from 'antd';
import { useEffect } from 'react';
import Configs from '../configs';
import { Header } from 'antd/es/layout/layout';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useMutation } from 'react-query';
import Request from '../libs/request';
import { useForm } from 'antd/es/form/Form';
import { useSelector } from 'react-redux';

import packageInfo from '../../package.json';

const HomePage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { logged } = useSelector((store) => store.auth);
  const [searchParams] = useSearchParams();

  const errorMessage = searchParams.get('message');
  if (errorMessage) {
    message.error(`Access Denied: ${errorMessage}`);
    searchParams.delete('message');
  }

  useEffect(() => {
    const scrollToSection = () => {
      if (location.hash) {
        const section = document.getElementById(location.hash.substring(1));
        if (section) {
          section.scrollIntoView({ behavior: 'smooth' });
        }
      }
    };
    scrollToSection();
    window.addEventListener('hashchange', scrollToSection, false);
    return () => {
      window.removeEventListener('hashchange', scrollToSection, false);
    };
  }, [location.hash]);

  const [contactForm] = useForm();
  const contactRequest = useMutation(
    async (payload) => {
      return Request.post('/contact', payload);
    },
    {
      onSuccess: () => {
        contactForm.resetFields();
        message.success('Message Sent Successfully');
      },
      onError: () => {
        message.error('Message Sending Failed');
      },
    }
  );

  return (
    <Layout
      className='home-layout'
      style={{ display: 'flex', flexDirection: 'column', zIndex: -1 }}
    >
      <Header
        style={{
          display: 'flex',
          alignItems: 'center',
          paddingInline: 'var(--window-offset)',
          background: '#fff',
          justifyContent: 'space-between',
        }}
      >
        <img className='logo' src='/reid-logo.svg' alt='reid logo' />
        <Menu
          style={{ width: '100%' }}
          theme='light'
          inlineCollapsed={false}
          mode='horizontal'
          defaultSelectedKeys={['0']}
          onSelect={({ key }) => navigate({ hash: key })}
        >
          <Menu.Item key='#home'>Home</Menu.Item>
          <Menu.Item key='#features'>Features</Menu.Item>
          <Menu.Item key='#contact'>Contact</Menu.Item>
        </Menu>
        <Space direction='horizontal' style={{ justifyContent: 'end' }}>
          {logged && logged?.orgs?.length && (
            <Button type='primary' onClick={() => navigate('/app')}>
              Open App
            </Button>
          )}

          {logged && logged?.isSuperAdmin === true && (
            <Button type='primary' onClick={() => navigate('/dashboard')}>
              Open Dashboard
            </Button>
          )}

          {!logged && <LoginButton />}
        </Space>
      </Header>

      <section
        id='home'
        style={{
          paddingInline: 'var(--window-offset)',
          paddingBlock: '4em',
          background: '#fff',
        }}
      >
        <Row style={{ alignItems: 'center' }}>
          <Col span={12}>
            <Space direction='vertical' size={20}>
              <Typography.Title level={1} style={{ marginBottom: '0px' }}>
                Empowering <mark>Digital ID</mark> Transformation
              </Typography.Title>
              <Typography.Text style={{ marginBottom: '0px' }}>
                Seamlessly Scan, Extract & Digitize ID Cards with maximum
                security
              </Typography.Text>
              <Button
                type='primary'
                onClick={() => navigate({ hash: 'contact' })}
              >
                Get Started
              </Button>
            </Space>
          </Col>
          <Col span={12}>
            <img
              src='/home-image-1.svg'
              style={{ width: '100%', height: '100%' }}
              alt='home reid'
            />
          </Col>
        </Row>
      </section>

      <section
        id='features'
        style={{
          paddingInline: 'var(--window-offset)',
          paddingBlock: '2em',
        }}
      >
        <Typography.Title level={1} style={{ textAlign: 'center' }}>
          Features
        </Typography.Title>
        <Row gutter={20}>
          <Col col={8} className='feature-item'>
            <img src='/feature-icon-1.svg' alt='feather reid' />
            <Typography.Title level={4}>
              Lightning-Fast Scanning
            </Typography.Title>
            <Typography.Text>
              Swiftly Capture ID data for seamless experince
            </Typography.Text>
          </Col>
          <Col span={8} className='feature-item'>
            <img src='/feature-icon-2.svg' alt='feather reid 2' />
            <Typography.Title level={4}>Precise Extraction</Typography.Title>
            <Typography.Text>
              Error-Free Data Extraction to Ensure Accuracy
            </Typography.Text>
          </Col>
          <Col span={8} className='feature-item'>
            <img src='/feature-icon-3.svg' alt='feature reid' />
            <Typography.Title level={4}>Bank-Grade Security</Typography.Title>
            <Typography.Text>
              Protecting your data with top-tier encryption for ultimate peace
              of mind
            </Typography.Text>
          </Col>
        </Row>
      </section>

      <section
        id='contact'
        style={{
          paddingInline: 'var(--window-offset)',
          paddingBlock: '2em',
          background: '#fff',
        }}
      >
        <Row gutter={30}>
          <Col span={12}>
            <Typography.Title level={1}>Contact Us</Typography.Title>
            <Typography.Text>
              We&apos;re here to assist you in every step of your journey with
              us. Whether you have questions about our services, need help
              getting started, or just want to say hello, we&apos;re just a
              message away.
            </Typography.Text>
          </Col>
          <Col span={12}>
            <Card>
              <Form
                form={contactForm}
                layout='vertical'
                onFinish={contactRequest.mutate}
              >
                <Form.Item
                  name='fullName'
                  label='Full Name'
                  rules={[
                    { required: true, message: 'Full Name is required!' },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  name='email'
                  label='Email Address'
                  rules={[
                    { required: true, message: 'Full Name is required!' },
                    {
                      pattern:
                        /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/ /* eslint-disable-line */,
                      message: 'Invalid Email Address',
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  name='subject'
                  label='Subject'
                  rules={[{ required: true, message: 'Subject is required!' }]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  name='message'
                  label='Message'
                  rules={[{ required: true, message: 'Message is required!' }]}
                >
                  <Input.TextArea rows={3} />
                </Form.Item>
                <Button
                  loading={contactRequest.isLoading}
                  type='primary'
                  htmlType='submit'
                >
                  Send
                </Button>
              </Form>
            </Card>
          </Col>
        </Row>
      </section>
      <p style={{ padding: '0em 1em', textAlign: 'right' }}>
        V{packageInfo.version}
      </p>
    </Layout>
  );
};

const LoginButton = () => {
  const meraIDAuth = window.meraIDAuth;
  useEffect(() => {
    meraIDAuth({
      container: '.meraid-auth-container',
      buttonStyle:
        'border-radius: var(--roundness);width: max-content;height: 40px',
      authApi: {
        method: 'POST',
        url: `${Configs.API_URL}/auth/login`,
      },
      collectApi: {
        method: 'POST',
        url: `${Configs.API_URL}/auth/collect`,
      },
      onSuccess: async ({ completionData }) => {
        localStorage.setItem('reid.auth.token', completionData.accessToken);
        window.location.href = '/app';
      },
    });
  }, [meraIDAuth]);

  return (
    <div style={{ zIndex: 999, position: 'relative', lineHeight: '1em' }}>
      <div className='meraid-auth-container' />
    </div>
  );
};

export default HomePage;
