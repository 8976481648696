import { configureStore } from "@reduxjs/toolkit";
import VerificationSlice from "./reducers/verification";
import AuthSlice from "./reducers/auth";
const store = configureStore({
  reducer: {
    auth: AuthSlice,
    verification: VerificationSlice,
  },
});
export default store;
